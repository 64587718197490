<template>
  <div class="container">
    <headers :titles="titles"></headers>
    <div class="column392">
      <div class="column-item date">
        <times></times>
        <div class="btn">首 页</div>
      </div>
      <div class="column-item" style="height:560px">
        <div class="column-item-title title-bg">
          <h4>物流站点列表</h4>
          <table class="big-data" style="height:500px">
            <thead>
              <tr>
                <th>站点名称</th>
                <th>地址</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
              <tr>
                <td>永嘉县上塘分部韵达快递</td>
                <td>上塘镇环城东路318号杜氏祠堂</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column-item" style="height:250px">
        <div class="column-item-title title-bg">
          <h4>站点建设情况</h4>
          <div class="site">
            <div class="site-item">
              <div class="img"><span>100%</span><span>覆盖率</span></div>
              <div class="title">乡镇级电商服务站</div>
              <div class="font-num">10<span>个</span></div>
            </div>
            <div class="site-item">
              <div class="img"><span>95%</span><span>覆盖率</span></div>
              <div class="title">村级电商服务点</div>
              <div class="font-num">56<span>个</span></div>
            </div>
            <div class="site-item">
              <div class="img"><span>82%</span><span>覆盖率</span></div>
              <div class="title">贫困村电商服务点</div>
              <div class="font-num">62<span>个</span></div>
            </div>
            <div class="site-item">
              <div class="img"><span>90%</span><span>覆盖率</span></div>
              <div class="title">乡镇商贸物流中心</div>
              <div class="font-num">16<span>个</span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column392">
      <div class="column-item btn-box">
        <div class="btn">直播数据</div>
        <div class="btn">溯源数据</div>
      </div>
      <div class="column-item" style="height:600px">
        <div class="column-item-title title-bg">
          <h4>TOP10站点交易额排行榜</h4>
          <table class="ranking colour" style="height:500px">
            <thead>
              <tr>
                <th>排名</th>
                <th>站点名称</th>
                <th>配送量</th>
                <th>交易额</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="num font-num">1</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">2</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">3</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">4</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">5</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">6</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">7</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
              <tr>
                <td class="num font-num">8</td>
                <td class="title180">永嘉瓯北礁下德邦物流德邦物流</td>
                <td class="font-num">5424</td>
                <td class="font-num">452147</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="column-item" style="height:290px">
        <div class="column-item-title title-bg">
          <h4>快递物流整合</h4>
          <div class="link">
            <div class="link-item">
              <img src="../assets/images/index/icon23.png" alt="">
            </div>
            <div class="link-item">
              <img src="../assets/images/index/icon24.png" alt="">
            </div>
            <div class="link-item">
              <img src="../assets/images/index/icon25.png" alt="">
            </div>
            <div class="link-item">
              <img src="../assets/images/index/icon26.png" alt="">
            </div>
            <div class="link-item">
              <img src="../assets/images/index/icon27.png" alt="">
            </div>
            <div class="link-item">
              <img src="../assets/images/index/icon28.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column1000">
      <div class="charts2">
        <div class="charts2-item">
          <img src="../assets/images/index/icon15.png" />
          <div>
            <div class="font-num num">2677</div>
            <div class="charts2-item-title">电商站点交易总额</div>
          </div>
        </div>
        <div class="charts2-item">
          <img src="../assets/images/index/icon16.png" />
          <div>
            <div class="font-num num">64%</div>
            <div class="charts2-item-title">同比增长</div>
          </div>
        </div>
        <div class="charts2-item">
          <img src="../assets/images/index/icon17.png" />
          <div>
            <div class="font-num num">15489</div>
            <div class="charts2-item-title">累计配送数量</div>
          </div>
        </div>
        <div class="charts2-item">
          <img src="../assets/images/index/icon18.png" />
          <div>
            <div class="font-num num">64%</div>
            <div class="charts2-item-title">同比增长</div>
          </div>
        </div>
      </div>
    </div>
    <div class="column1050">
      <div class="popup-box">
        <div class="popup-box-item">
          <div class="close-box"></div>
          <div class="charts">
            <v-chart :options="optionbar" />
          </div>
        </div>
        <div class="popup-box-item">
          <div class="close-box"></div>
          <div class="charts">
            <v-chart :options="optionline" />
          </div>
        </div>
        <div class="popup-box-item">
          <div class="close-box"></div>
          <div class="charts">
            <v-chart :options="optionbar1" />
          </div>
        </div>
        <div class="popup-box-item">
          <div class="close-box"></div>
          <div class="charts">
            <v-chart :options="optionline1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import headers from "@/components/header.vue";
  import times from "@/components/time.vue";
  import ECharts from "vue-echarts";
  import "echarts/lib/chart/line";
  import "echarts/lib/chart/bar";
  export default {
    name: 'logisticsPopup',
    components: {
      "v-chart": ECharts,
      headers,
      times
    },
    data() {
      return {
        titles: '永嘉农村电商大数据驾驶舱',
        optionbar: {
          title: {
            show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
            text: '电商站点交易额明细（万）', //主标题文本，'\n'指定换行
            x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
            y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
            textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
            backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
            borderColor: '#ccc', //标题边框颜色,默认'#ccc'
            borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
            padding: [17, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 'normal',
              color: "#fff"
            },
          },
          grid: {
            top: '25%', //距上边距
            left: '10%', //距离左边距
            right: '10%', //距离右边距
            bottom: "15%", //距离下边距
          },
          legend: {
            top: 40,
            right: "10%",
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 15,
            },
          },
          xAxis: [{
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            // 修改文本的颜色
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改轴线的颜色
          }, ],
          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改y轴横向分割线的颜色
            splitLine: {
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                color: ["#5f666a"],
              },
            },
          }, ],
          series: [{
              name: "去年",
              type: "bar",
              barWidth: "30%",

              itemStyle: {
                normal: {
                  //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                  barBorderRadius: [20, 20, 0, 0],
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: "#111",
                    },
                    {
                      offset: 1,
                      color: "#CBB012",
                    },
                  ]),
                },
              },
              data: [79, 38, 55, 62, 58, 79, 79, 38, 55, 62, 58, 79],
            },
            {
              name: "今年",
              type: "bar",
              itemStyle: {
                normal: {
                  //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                  barBorderRadius: [20, 20, 0, 0],
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: "#111",
                    },
                    {
                      offset: 1,
                      color: "#46F9FD",
                    },
                  ]),
                },
              },
              barWidth: "30%",
              data: [45, 45, 82, 65, 85, 43, 45, 45, 82, 65, 85, 43],
            },
          ],
        },
        optionbar1: {
          title: {
            show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
            text: '配送数量明细（件）', //主标题文本，'\n'指定换行
            x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
            y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
            textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
            backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
            borderColor: '#ccc', //标题边框颜色,默认'#ccc'
            borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
            padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 'normal',
              color: "#fff"
            },
          },
          grid: {
            top: '25%', //距上边距
            left: '10%', //距离左边距
            right: '10%', //距离右边距
            bottom: "15%", //距离下边距
          },
          legend: {
            top: 40,
            right: "10%",
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 15,
            },
          },
          xAxis: [{
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            // 修改文本的颜色
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改轴线的颜色
          }, ],
          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改y轴横向分割线的颜色
            splitLine: {
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                color: ["#5f666a"],
              },
            },
          }, ],
          series: [{
              name: "去年",
              type: "bar",
              barWidth: "30%",

              itemStyle: {
                normal: {
                  //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                  barBorderRadius: [20, 20, 0, 0],
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: "#111",
                    },
                    {
                      offset: 1,
                      color: "#62DEAF",
                    },
                  ]),
                },
              },
              data: [79, 38, 55, 62, 58, 79, 79, 38, 55, 62, 58, 79],
            },
            {
              name: "今年",
              type: "bar",
              itemStyle: {
                normal: {
                  //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                  barBorderRadius: [20, 20, 0, 0],
                  color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                      offset: 0,
                      color: "#111",
                    },
                    {
                      offset: 1,
                      color: "#9C74EE",
                    },
                  ]),
                },
              },
              barWidth: "30%",
              data: [45, 45, 82, 65, 85, 43, 45, 45, 82, 65, 85, 43],
            },
          ],
        },
        optionline: {
          title: {
            show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
            text: '交易额增幅（万）', //主标题文本，'\n'指定换行
            x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
            y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
            textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
            backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
            borderColor: '#ccc', //标题边框颜色,默认'#ccc'
            borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
            padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 'normal',
              color: "#fff"
            },
          },
          grid: {
            top: '25%', //距上边距
            left: '10%', //距离左边距
            right: '10%', //距离右边距
            bottom: "15%", //距离下边距
          },
          legend: {
            top: 40,
            right: "10%",
            icon: "roundRect",
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 15,
            },
          },
          xAxis: [{
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            // 修改文本的颜色
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改轴线的颜色
          }, ],
          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改y轴横向分割线的颜色
            splitLine: {
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                color: ["#5f666a"],
              },
            },
          }, ],
          series: [{
              name: "去年",
              type: "line",
              smooth: true,
              color: ["#CBB012"],
              lineStyle: {
                width: 5,
              },
              data: [79, 38, 55, 62, 58, 79, 79, 38, 55, 62, 58, 79],
            },
            {
              name: "今年",
              type: "line",
              smooth: true,
              color: ["#46F9FD"],
              lineStyle: {
                width: 5,
              },
              data: [45, 45, 82, 65, 85, 43, 45, 45, 82, 65, 85, 43],
            },
          ],
        },
        optionline1: {
          title: {
            show: true, //显示策略，默认值true,可选为：true（显示） | false（隐藏）
            text: '配送数量增幅（件）', //主标题文本，'\n'指定换行
            x: 'center', //水平安放位置，默认为'left'，可选为：'center' | 'left' | 'right' | {number}（x坐标，单位px）
            y: 'top', //垂直安放位置，默认为top，可选为：'top' | 'bottom' | 'center' | {number}（y坐标，单位px）
            textAlign: null, //水平对齐方式，默认根据x设置自动调整，可选为： left' | 'right' | 'center
            backgroundColor: 'rgba(0,0,0,0)', //标题背景颜色，默认'rgba(0,0,0,0)'透明
            borderColor: '#ccc', //标题边框颜色,默认'#ccc'
            borderWidth: 0, //标题边框线宽，单位px，默认为0（无边框）
            padding: [20, 0, 0, 0], //标题内边距，单位px，默认各方向内边距为5，接受数组分别设定上右下左边距
            textStyle: { //主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              fontSize: 20,
              fontStyle: 'normal',
              fontWeight: 'normal',
              color: "#fff"
            },
          },
          grid: {
            top: '25%', //距上边距
            left: '10%', //距离左边距
            right: '10%', //距离右边距
            bottom: "15%", //距离下边距
          },
          legend: {
            top: 40,
            right: "10%",
            icon: "roundRect",
            textStyle: {
              //图例文字的样式
              color: "#fff",
              fontSize: 15,
            },
          },
          xAxis: [{
            type: "category",
            data: [
              "1月",
              "2月",
              "3月",
              "4月",
              "5月",
              "6月",
              "7月",
              "8月",
              "9月",
              "10月",
              "11月",
              "12月",
            ],
            // 修改文本的颜色
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改轴线的颜色
          }, ],
          yAxis: [{
            type: "value",
            axisLabel: {
              color: "#556f95",
              fontSize: 10,
            },
            // 修改y轴横向分割线的颜色
            splitLine: {
              lineStyle: {
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
                color: ["#5f666a"],
              },
            },
          }, ],
          series: [{
              name: "去年",
              type: "line",
              smooth: true,
              color: ["#62DEAF"],
              lineStyle: {
                width: 5,
              },
              data: [79, 38, 55, 62, 58, 79, 79, 38, 55, 62, 58, 79],
            },
            {
              name: "今年",
              type: "line",
              smooth: true,
              color: ["#9C74EE"],
              lineStyle: {
                width: 5,
              },
              data: [45, 45, 82, 65, 85, 43, 45, 45, 82, 65, 85, 43],
            },
          ],
        },
      };
    },
    mounted() {},
    methods: {}
  }
</script>

<style lang="scss" scoped>
  @import '../assets/css/pubilc.css';
  @import '../assets/css/font.css';

  .container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 1920px;
    height: 1080px;
    padding: 0 30px;
    box-sizing: border-box;
    background: url('../assets/images/index/bg1.png') no-repeat center #000;

    .header {
      width: 1917px;
      height: 167px;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -958px;
      z-index: 0;
      background: url('../assets/images/index/header-bg.png') no-repeat center;

      h1 {
        font-size: 40px;
        font-family: PangMenZhengDao;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 32px;
        text-align: center;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }

    .footer {
      width: 1075px;
      height: 59px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -537px;
      z-index: 0;
      background: url('../assets/images/index/footer-bg.png') no-repeat;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 30px;
        font-family: PangMenZhengDao;
        font-weight: 400;
        color: #FFFFFF;
        text-shadow: 0 0 0.2em #fff,
          -0 -0 0.2em #065abe;
      }
    }
  }

  .column392 {
    width: 392px;
    margin-top: 75px;
    z-index: 10;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 80px;
  }

  .big-data-box {
    height: 600px !important;
  }

  .column-item {
    width: 100%;

    .column-item-title {
      width: 100%;
      height: 60px;

      h4 {
        line-height: 60px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
      }

      .big-data {
        width: 100%;

        th {
          font-size: 20px;
          font-weight: bold;
          color: #556F95;
          line-height: 218%;
        }

        td {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 20px;
        }
      }

      .colour {
        tbody {
          tr {
            &:nth-child(1) {
              .num {
                color: #E5772E;
              }

            }

            &:nth-child(2) {
              .num {
                color: #F19E6C;
              }
            }

            &:nth-child(3) {
              .num {
                color: #F1CD6C;
              }
            }
          }
        }
      }

      .ranking {
        width: 100%;

        tr {
          height: 33px;
          line-height: 33px;
        }

        th {
          font-size: 20px;
          font-weight: bold;
          color: #556F95;
        }

        td {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 20px;
        }

        .title180 {
          width: 180px;
        }
      }
    }
  }

  .date {
    background: url('../assets/images/index/date-icon.png') no-repeat left center;
    height: 28px;
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 40px;
  }

  .charts2 {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: flex-end;

    .charts2-item {
      width: 22%;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;

      div {
        .num {
          font-size: 28px;
          font-weight: bold;
          color: #00E4FF;
        }

        .charts1-item-title {
          font-size: 16px;
          font-weight: bold;
          color: #FFFFFF;
        }
      }
    }
  }

  .btn-box {
    height: 44px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 24px;
  }

  .column1000 {
    width: 1000px;
    position: absolute;
    left: 50%;
    top: 130px;
    margin-left: -500px;
  }

  .monitor {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;

    .monitor-item {
      width: 50%;
      margin: 10px 0;

      .num {
        font-size: 26px;
        font-weight: bold;

        span {
          font-size: 12px;
          font-weight: normal;
        }
      }

      .title {
        font-size: 14px;
        font-weight: bold;
        color: #64CDF9;
      }
    }
  }

  .site {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 0 30px;

    .site-item {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px 0;

      .img {
        display: flex;
        width: 123px;
        height: 113px;
        flex-direction: column;
        align-items: center;

        span {
          &:nth-child(1) {
            margin-top: 15px;
            font-size: 22px;
            font-weight: 400;
            color: #FFFFFF;
          }

          &:nth-child(2) {
            font-size: 10px;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }

      &:nth-child(1) {
        .img {
          background: url('../assets/images/index/icon19.png') no-repeat;
        }
      }

      &:nth-child(2) {
        .img {
          background: url('../assets/images/index/icon20.png') no-repeat;
        }
      }

      &:nth-child(3) {
        .img {
          background: url('../assets/images/index/icon21.png') no-repeat;
        }
      }

      &:nth-child(4) {
        .img {
          background: url('../assets/images/index/icon22.png') no-repeat;
        }
      }

      .title {
        margin-top: -25px;
      }

    }
  }

  .link {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;


    .link-item {
      transition: 1s;
      -webkit-transition: 1s;

      &:hover {
        transform: rotate3d(0, 1, 0, 180deg);
      }
    }


  }

  .column1050 {
    width: 1050px;
    position: absolute;
    left: 50%;
    top: 240px;
    margin-left: -525px;
  }

  .popup-box {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    .popup-box-item {
      width: 520px;
      height: 331px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 10px;
      position: relative;
      margin-bottom: 10px;

      .close-box {
        width: 17px;
        height: 17px;
        background: url('../assets/images/index/close.png') no-repeat;
        position: absolute;
        top: 16px;
        right: 16px;
        z-index: 9999;
        transition: 1s;
        -webkit-transition: 1s;

        &:hover {
          cursor: pointer;
          transform: rotate(180deg);
        }
      }

      .charts {
        width: 100%;
        height: 331px;
        overflow: hidden;

        .echarts {
          width: 520px;
          height: 331px;
        }
      }
    }
  }
</style>